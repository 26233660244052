import { nonPersistedBaseApi, isQueryFnErrorResponse } from '@/store/queries';
import { sortTasks } from '@/store/helpers/task';
import Features from '@/helpers/features';
import { EventCounter } from '@/types/api/persons';
import type { Services } from '@/services';

const { seocsEnabled, seocsTemplateIdKey } = Features;

/*
  Some documentation on rtk-query
  https://redux-toolkit.js.org/rtk-query/api/createApi
  https://redux-toolkit.js.org/rtk-query/usage/code-splitting
  https://redux-toolkit.js.org/rtk-query/usage/server-side-rendering
  https://redux-toolkit.js.org/rtk-query/usage/customizing-queries#examples---queryfn
  https://redux-toolkit.js.org/rtk-query/usage/migrating-to-rtk-query
  https://redux-toolkit.js.org/rtk-query/usage/streaming-updates
*/

// Only one API should be defined and extended in separate files like so

// TODO: Add actual type to line 43 & line 86 instead of 'any'
export const notificationsApi = nonPersistedBaseApi.injectEndpoints({
  endpoints: (build) => ({
    // TODO: this should probably move to queries/workflows
    getTasks: build.query({
      async queryFn(
        { personId, applicationId, token }:
        { personId: string, applicationId?: string, token?: string },
        { extra },
      ) {
        const { SagasService } = extra as Services;
        try {
          const results = await SagasService.setToken(token)
            .setServerSideBaseUrl()
            .getTasksByPersonId(personId);
          const { data: { tasks } } = results;
          const sortedTasks = sortTasks(tasks, applicationId);
          return { data: sortedTasks };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
      providesTags: ['Tasks'],
    }),
    updateTask: build.mutation({
      async queryFn(
        {
          personId,
          taskId,
          taskStatus,
        }: { personId: string; taskId: string; taskStatus: string },
        { extra },
      ) {
        const { SagasService } = extra as Services;
        await SagasService.updateTask(personId, taskId, { status: taskStatus });
        return { data: null };
      },
      invalidatesTags: ['Tasks'],
    }),
    getNotifications: build.query({
      async queryFn(
        { personId, token }: { personId: string; token?: string },
        { extra },
      ) {
        const { CommunicationService } = extra as Services;
        try {
          const results = await CommunicationService.setToken(token)
            .setServerSideBaseUrl()
            .getNotificationsByPersonId(personId);
          const { data: { notifications } }: { data: { notifications: any[] } } = results;
          if (seocsEnabled) {
            return { data: notifications
              .filter((notification) => notification.templateId !== seocsTemplateIdKey) };
          }
          return { data: notifications };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
      providesTags: ['Notifications'],
    }),
    updateNotificationAsReadOrUnread: build.mutation({
      async queryFn(
        {
          personId,
          notificationId,
          read,
        }: { personId: string; notificationId: string; read: boolean },
        { extra },
      ) {
        const { CommunicationService } = extra as Services;
        await CommunicationService.putNotificationAsReadOrUnread(
          personId,
          notificationId,
          read,
        );
        return { data: null };
      },
      invalidatesTags: ['Notifications'],
    }),
    // TODO: this should probably move to queries/persons
    getEvents: build.query<EventCounter, unknown>({
      async queryFn(
        { personId, token }: { personId: string; token?: string },
        { extra },
      ) {
        const { PersonsService } = extra as Services;
        try {
          const results = await PersonsService.setToken(token)
            .setServerSideBaseUrl()
            .getEventsByPersonId(personId);
          return { data: results.data };
        } catch (error: unknown) {
          if (isQueryFnErrorResponse(error)) {
            const { response: { statusText, status } = {} } = error;
            return { error: { message: statusText, statusCode: status } };
          }
          throw error;
        }
      },
    }),
  }),
});

export const {
  useGetTasksQuery,
  useUpdateTaskMutation,
  useGetNotificationsQuery,
  useUpdateNotificationAsReadOrUnreadMutation,
  useGetEventsQuery,
} = notificationsApi;
