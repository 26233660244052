import configs from '@/helpers/config';
import makeAbsoluteUrl from '@/helpers/absolute-url';

/* eslint-disable quote-props */
const constant = {
  tiles: [
    {
      icon: 'icon-go-to-class-easel-mc',
      text: 'Go to Class',
      url: configs.blackboard.baseUrl,
    },
    {
      icon: 'icon-microsoft',
      text: 'Office 365',
      url: 'https://login.microsoftonline.com/login.srf?wreply=https%3A%2F%2Fportal.office.com&whr=email.phoenix.edu&wa=wsignin1.0',
    },
    {
      icon: 'icon-library-mc',
      text: 'Library',
      url: 'https://library.phoenix.edu/home',
    },
    {
      icon: 'icon-phoenix-link-mc',
      text: 'PhoenixLink',
      url: 'https://phoenix.peoplegrove.com/',
    },
  ],
  copyrightLinks: [
    { text: 'Privacy Policy', url: 'https://www.phoenix.edu/copyright-legal/privacy_policy.html' },
    { text: 'Manage Cookie Preferences', url: '#', id: 'footer-copyrightLink-cookie-preferences' },
    { text: 'Terms of Use', url: 'https://www.phoenix.edu/copyright-legal/terms_and_conditions.html' },
    { text: 'Policy and Compliance', url: makeAbsoluteUrl('/policy-and-compliance.html') },
    { text: 'Help', url: makeAbsoluteUrl('/public/search.html') },
    { text: 'TeamViewer', url: 'http://tv.phoenix.edu/' },
    { text: 'Share Your Screen', url: '#cobrowse' },
    {
      text: 'CA: Do Not Sell',
      url: 'https://www.phoenix.edu/copyright-legal/ccpa.html#donotsell',
      alwaysUnderlined: true,
    },
  ],
  copyrightText: 'Copyright © 2023 University of Phoenix',
  logo: {
    text: 'MyPhoenix',
    url: makeAbsoluteUrl('/home.html'),
    icon: 'icon-phoenix-logo',
  },
  linkLists: [
    {
      linkList: 'Library Resources',
      links: [
        {
          text: 'University Library',
          url: 'https://library.phoenix.edu/home',
        },
        {
          text: 'Citation Generator',
          url: 'https://multimedia.phoenix.edu/cms/citation',
        },
        {
          text: 'Center for Writing Excellence',
          url: 'https://multimedia.phoenix.edu/cms/cwe/',
        },
        {
          text: 'Center for Math Excellence',
          url: 'https://multimedia.phoenix.edu/cms/cme',
        },
        {
          text: 'Grammar Checking Tools',
          url: 'https://multimedia.phoenix.edu/cms/202010168',
        },
        {
          text: 'Voter Registration',
          url: 'https://www.eac.gov/voters/register-and-vote-in-your-state',
        },
      ],
    },
    {
      linkList: 'Career & Alumni',
      links: [
        {
          text: 'PhoenixLink',
          url: 'https://phoenix.peoplegrove.com/',
        },
        {
          text: 'Alumni Association',
          url: 'https://www.phoenix.edu/alumni/',
        },
        {
          text: 'Mentor Program',
          url: 'https://www.phoenix.edu/alumni/mentor-programs.html',
        },
      ],
    },
    {
      linkList: 'Student Resources',
      links: [
        {
          text: 'My Phoenix Store',
          url: 'http://store.phoenix.edu/',
        },
        {
          text: 'Transcripts',
          url: 'https://ecampus.phoenix.edu/Portal/Portal/Public/Login.aspx?ReturnUrl=https%3A%2F%2Fportal.phoenix.edu%2Fte%2Ftranscripts%2Findex.html',
        },
        {
          text: 'Letter Center',
          url: 'https://ecampus.phoenix.edu/Program/LetterCenter',
        },
        {
          text: 'Current Scholarship Opportunities',
          url: 'http://www.phoenix.edu/tuition_and_financial_options/scholarships.html',
        },
        {
          text: 'Military Resources',
          url: makeAbsoluteUrl('/vsu/military-resources.html'),
        },
        {
          text: 'Tk20 / Time Logs',
          url: 'https://sso.phoenix.edu/?callback=https%3A%2F%2Fnewclassroom3.phoenix.edu%2FBlti%2F3%2Furn%3Aapol%3AtenantId%3A831D8B107020BBEBE0400F0A32207789%2Furn%3Aapol%3AcproviderId%3A2d9a1488-9b1d-4d03-ba8c-442cede11ba3%2Fnoncontextlaunch',
          group: {
            'ART42': 1,
            'BSHS': 1,
            'BSED': 1,
            'BSED/E': 1,
            'BSED/E-ELA': 1,
            'BSED/E-MTH': 1,
            'BSED/E-SCI': 1,
            'BSED/E-SOC': 1,
            'BSED/ECH': 1,
            'BSHS/AD': 1,
            'BSHS/ADD': 1,
            'BSHS/ADDC': 1,
            'BSHS/FC': 1,
            'BSHS/FCS': 1,
            'BSHS/FCSC': 1,
            'BSHS/GER': 1,
            'BSHS/GERC': 1,
            'BSHS/GR': 1,
            'BSHS/HAS': 1,
            'BSHS/HSA': 1,
            'BSHS/HSM': 1,
            'BSHS/M': 1,
            'BSHS/MC': 1,
            'BSLS': 1,
            'BSN': 1,
            'CERT/ALT-E': 1,
            'CERT/ALT-S': 1,
            'CERT/AP-E': 1,
            'CERT/AP-MG': 1,
            'CERT/AP-S': 1,
            'CERT/AP-SE': 1,
            'CERT/G-ELM': 1,
            'CERT/G-SEC': 1,
            'CERT/G-SPE': 1,
            'CERT/SPE': 1,
            'COLEXT-ED': 1,
            'DBA': 1,
            'DHA': 1,
            'DM': 1,
            'DM/IST': 1,
            'DNP': 1,
            'EDD': 1,
            'EDD/CI': 1,
            'EDD/ET': 1,
            'EDD/HEA': 1,
            'MAED/ADM': 1,
            'MAED/ADMIN': 1,
            'MAED/SPE': 1,
            'MAED/TED-E': 1,
            'MAED/TED-S': 1,
            'MAED/TEDMG': 1,
            'MAED/TEDMS': 1,
            'MAED/TEDSM': 1,
            'MAED/TEDSS': 1,
            'MPH/CHL': 1,
            'MSC/CCMH': 1,
            'MSC/MFC': 1,
            'MSC/MFCT': 1,
            'MSC/MHC': 1,
            'MSC/SC': 1,
            'MSN': 1,
            'MSN/FNP': 1,
            'PHD/HEA': 1,
            'PHD/IO': 1,
            'PHD/NUR': 1,
            'PLC': 1,
          },
        },
      ],
    },
    {
      linkList: 'Connect',
      links: [
        {
          text: 'Help & Support',
          url: makeAbsoluteUrl('/university-contacts.html'),
        },
        {
          text: 'Refer a Friend',
          url: 'https://www.phoenix.edu/request/refer-a-friend?detail=myphx&channel=ACRF',
        },
      ],
    },
  ],
};

export default constant;
