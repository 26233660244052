/* eslint-disable quote-props */
import Config from '@/helpers/config';
import constants from '@/constants/skills';

const headerLinks = {
  headerLinkList: [
    {
      header: 'Study & Learn',
      links: [
        {
          text: 'Learning Resources (Library)',
          group: { all: 1 },
          url: '/vsu/academic-resources.html#learning-resources',
        },
        {
          text: 'Office 365',
          group: { all: 1 },
          url: 'https://www.office.com/login?login_hint=USERS_UNIVERSITY_EMAIL',
        },
        {
          text: 'Tk20 / Time Logs',
          group: {
            'ART42': 1,
            'BSHS': 1,
            'BSED': 1,
            'BSED/E': 1,
            'BSED/E-ELA': 1,
            'BSED/E-MTH': 1,
            'BSED/E-SCI': 1,
            'BSED/E-SOC': 1,
            'BSED/ECH': 1,
            'BSHS/AD': 1,
            'BSHS/ADD': 1,
            'BSHS/ADDC': 1,
            'BSHS/FC': 1,
            'BSHS/FCS': 1,
            'BSHS/FCSC': 1,
            'BSHS/GER': 1,
            'BSHS/GERC': 1,
            'BSHS/GR': 1,
            'BSHS/HAS': 1,
            'BSHS/HSA': 1,
            'BSHS/HSM': 1,
            'BSHS/M': 1,
            'BSHS/MC': 1,
            'BSLS': 1,
            'BSN': 1,
            'CERT/ALT-E': 1,
            'CERT/ALT-S': 1,
            'CERT/AP-E': 1,
            'CERT/AP-MG': 1,
            'CERT/AP-S': 1,
            'CERT/AP-SE': 1,
            'CERT/G-ELM': 1,
            'CERT/G-SEC': 1,
            'CERT/G-SPE': 1,
            'CERT/SPE': 1,
            'COLEXT-ED': 1,
            'DBA': 1,
            'DHA': 1,
            'DM': 1,
            'DM/IST': 1,
            'DNP': 1,
            'EDD': 1,
            'EDD/CI': 1,
            'EDD/ET': 1,
            'EDD/HEA': 1,
            'MAED/ADM': 1,
            'MAED/ADMIN': 1,
            'MAED/SPE': 1,
            'MAED/TED-E': 1,
            'MAED/TED-S': 1,
            'MAED/TEDMG': 1,
            'MAED/TEDMS': 1,
            'MAED/TEDSM': 1,
            'MAED/TEDSS': 1,
            'MPH/CHL': 1,
            'MSC/CCMH': 1,
            'MSC/MFC': 1,
            'MSC/MFCT': 1,
            'MSC/MHC': 1,
            'MSC/SC': 1,
            'MSN': 1,
            'MSN/FNP': 1,
            'PHD/HEA': 1,
            'PHD/IO': 1,
            'PHD/NUR': 1,
            'PLC': 1,
          },
          url: 'https://sso.phoenix.edu/?callback=https%3A%2F%2Fnewclassroom3.phoenix.edu%2FBlti%2F3%2Furn%3Aapol%3AtenantId%3A831D8B107020BBEBE0400F0A32207789%2Furn%3Aapol%3AcproviderId%3A2d9a1488-9b1d-4d03-ba8c-442cede11ba3%2Fnoncontextlaunch',
        },
        {
          text: 'CDS Central',
          group: { DO: 1 },
          url: 'https://multimedia.phoenix.edu/doctoral-studies/',
        },
        {
          text: 'Research Hub',
          group: { DO: 1 },
          url: 'https://research.phoenix.edu/',
        },
        {
          text: 'COE Central',
          group: {
            'ART42': 1,
            'BSED/ECH': 1,
            'BSED/E': 1,
            'BSED/ES': 1,
            'BSLS': 1,
            'CERT/AP-S': 1,
            'CERT/AP-E': 1,
            'CERT/AP-SE': 1,
            'COLEXT-ED': 1,
            'MAED/ADM': 1,
            'MAED/ADM2': 1,
            'MAED/AET': 1,
            'MAED/CI': 1,
            'MAED/ES': 1,
            'MAED/TED-E': 1,
            'MAED/TED-S': 1,
            'MAED/SPE': 1,
            'ND-EDU': 1,
          },
          url: 'https://multimedia.phoenix.edu/education/',
          target: '_blank',
        },
        {
          text: 'BSHS Field Experience Resources',
          group: {
            'ART42': 1,
            'BSHS': 1,
          },
          url: 'https://multimedia.phoenix.edu/cms/20204296',
        },
        {
          text: 'BSSW Library Guide',
          group: {
            'ART42': 1,
            'BSSW': 1,
          },
          url: 'https://library.phoenix.edu/social_work/home',
        },
        {
          text: 'MSC/CCMH Field Experience Preparation',
          group: {
            'ART42': 1,
            'MSC/CCMH': 1,
          },
          url: 'https://portal.phoenix.edu/api/contentservices/rest/contentresolver/02-DOCUMENT-5cdd8a75357d5dc4a27d186d',
        },
        {
          text: 'MCS/CCMH Clinical Experience Resources',
          group: {
            'ART42': 1,
            'MSC/CCMH': 1,
          },
          url: 'https://multimedia.phoenix.edu/cms/20204500',
        },
        {
          text: 'College of Social and Behavorial Sciences Resources',
          group: {
            'ART42': 1,
            'BSHS': 1,
            'MSC/CCMH': 1,
            'MSC/MFCT': 1,
          },
          url: 'https://multimedia.phoenix.edu/cms/college-of-social-and-behavioral-sciences-resources',
        },
      ],
    },
    {
      header: 'Connect',
      links: [
        {
          text: 'Help & Support',
          group: { all: 1 },
          url: '/university-contacts.html',
        },
        {
          text: 'My Email Inbox',
          group: { all: 1 },
          url: 'https://outlook.office365.com/owa/?realm=email.phoenix.edu',
        },
        {
          text: 'Military Resources',
          group: { all: 1 },
          url: '/vsu/military-resources.html',
        },
      ],
    },
    {
      header: 'Plan & Prepare',
      links: [
        {
          text: 'Skills Profile',
          group: { '3CASkills': 1 },
          url: `${constants.skillsLinkUrl}`,
        },
        {
          text: 'Academic Plan',
          group: { all: 1 },
          url: '/academic-plan.html',
        },
        {
          text: 'Financial Plan',
          group: { all: 1 },
          url: `${Config.ecampus.baseUrl}financialplan/`,
        },
        {
          text: 'Career Resources',
          group: { all: 1 },
          url: 'https://phoenix.peoplegrove.com/',
        },
      ],
    },
  ],
  iconLinks: [
    {
      text: 'Help',
      url: '/public/search.html',
      icon: 'icon-help',
    },
  ],
  siteStripeOn: false,
  siteStripeAriaLabel: 'site-stripe_alert',
  siteStripeMessage: '<p>University of Phoenix login will be unavailable between 4:00 am – 8:00 am AZ time, on June 9, 2022, while we are improving the site-wide login process.</p>\r\n',
  siteStripeCtaText: '',
  siteStripeCtaUrl: '',
  emailInboxLink: 'https://outlook.office365.com/owa/?realm=email.phoenix.edu',
  enableSearch: false,
  searchLandingPagePath: '',
  messageCenterLink: '/message-center.html',
  myPhoenixLink: '/home.html',
  logout: {
    text: 'Logout',
    icon: 'icon-logout',
  },
};

export default headerLinks;
